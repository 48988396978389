import { Post, Delete } from "./ApiBase";

export async function PostRemoveAllResumes() {
    return await Post("/api/RemoveAllResumes", {});
}

export async function PostRemoveAccount() {
    return await Post("/api/RemoveAccount", {});
}

export async function CancelSubscription() {
    return await Delete("/api/CancelSubscription", {});
}